/* Base styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9fafb;
}

/* Navigation */
.navbar {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.nav-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.logo-icon {
    width: 32px;
    height: 32px;
}

.logo-text {
    font-size: 1.25rem;
    font-weight: 600;
}

.desktop-menu {
    display: none;
}

.menu-item {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    cursor: pointer;
    text-transform: capitalize;
}

.menu-item:hover {
    color: #111827;
}

.menu-item.active {
    color: #2563eb;
}

.mobile-menu-button {
    display: block;
    padding: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #6b7280;
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    background-color: white;
}

.mobile-menu-item {
    padding: 0.75rem 1rem;
    border: none;
    background: none;
    text-align: left;
    font-size: 1rem;
    color: #4b5563;
    cursor: pointer;
}

.mobile-menu-item:hover {
    background-color: #f3f4f6;
}

/* Hero Section */
.hero {
    background-color: white;
    padding: 4rem 1rem;
    text-align: center;
}

.hero h1 {
    font-size: 3rem;
    font-weight: 800;
    color: #111827;
    line-height: 1.2;
}

.hero p {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: #6b7280;
}

/* Gallery */
.gallery {
    flex: 1;
    max-width: 1280px;
    margin: 0 auto;
    padding: 3rem 1rem;
}

.gallery-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

.gallery-item {
    flex: 0 0 100%;
    position: relative;
    height: 256px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 0.5rem;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
    border-radius: 0.5rem;
}

.gallery-item:hover .overlay {
    background: rgba(0, 0, 0, 0.3);
}

.photo-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    color: white;
    opacity: 0;
    transition: opacity 0.2s;
}

.gallery-item:hover .photo-info {
    opacity: 1;
}

.photo-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
}

.photo-info p {
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

/* Modal */
.modal {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 50;
}

.modal-content {
    max-width: 56rem;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.modal-content img {
    width: 100%;
    height: auto;
}

.modal-info {
    padding: 1rem;
}

.modal-info h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.modal-info p {
    margin-top: 0.25rem;
    color: #4b5563;
}

/* Footer */
.footer {
    background-color: #111827;
    color: white;
    padding: 3rem 1rem;
}

.footer-content {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer-section h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.social-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.social-icon:hover {
    color: #d1d5db;
}

.hours-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

/* Media Queries */
@media (min-width: 768px) {
    .desktop-menu {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    .mobile-menu-button {
        display: none;
    }

    .gallery-item {
        flex: 0 0 calc(50% - 0.75rem);
    }

    .footer-content {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-section {
        flex: 1;
    }
}

@media (min-width: 1024px) {
    .gallery-item {
        flex: 0 0 calc(33.333% - 1rem);
    }
}
